.max-home .mobile-ma{
	position: relative;
	width: 100%;
	height: 100%;
}
.max-home .mobile-model1-video1{
	padding-top: 0rem;
	width: 100%;
	height: 240px;
}
.max-home .mobile-model1-info{
	padding-left: 55px;
	padding-right: 55px;
	margin-top: 20px;
	padding-top: 55px;
	margin-bottom: 15px;
}
.max-home .mobile-model1-info-b{
	padding-left: 44px;
	padding-right: 44px;
}

.max-home .mobile-model1-info>h3, .max-home .mobile-model1-info-b>h3{
	line-height: 30px;
	font-size: 24px;
	color: #fff;
	text-align: center;
}
.max-home .mobile-model1-info>p, .max-home .mobile-model1-info-b>p{
	line-height: 20px;
	font-size: 15px;
	color: #9B9B9B;
	text-align: justify;
	margin-bottom: 23px;
}

.max-home .mobile-model1-info-b>p.smallnote{
	line-height: 14px;
	font-size: 8px;
	color: #FFF;
	text-align: left;
	margin-bottom: 0px;
}