.offer-full {
    position: absolute;
    width: 1500px;
    top: 50%;
    margin-top: -280px;
    left: 50%;
    margin-left: -720px;
}

.offer-col-full-right {
    display: inline-block;
    vertical-align: top;
    /* width: 45%; */
    margin-top: 65px;
}

.offer-box h3 {
    white-space: pre-line;
}

.offer-box .red {
    color: red;
}

.offer-col-full-left {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin-top: 10px;
}

.offer-full-image-container {
    position: relative;
    width: 711px;
    height: 397px;
}

.offer-full-image-container > img {
    width: 100%;
    height: 100%;
}

.offer-full-image-container .free-gift-wrapper {
    position: absolute;
    bottom: 16px;
    right: 23px;
    z-index: 2;
    cursor: pointer;
}

.offer-full-image-container .free-gift-wrapper img {
    width: 150px;
    height: 150px;
}

.offer-box {
    text-align: center;
    position: relative;
    margin-right: 20px;
    border: 2px solid #4A4A4A;
    padding: 20px 10px;
    display: inline-block;
    vertical-align: top;
    color: #F8E71C;
    width: 210px;
    height: 330px;
}

.offer-box-build-service {
    color: #FF8800;
}

.offer-box-build-service img {
    width: auto;
    height: 50px;
    margin-bottom: 10px;
}

.offer-box h3 {
    line-height: 23px;
    font-size: 19px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    height: 50px;
    margin-bottom: 10px;
}

.offer-box p {
    line-height: 19px;
    font-size: 15px;
}

.offer-box p span {
    display: block;
}

.offer-box .price {
    position: absolute;
    bottom: 15px;
    left: 0px;
    width: 100%;
}

.offer-box .price .discount {
    font-weight: bold;
    width: 86%;
    margin-top: 13px;
    height: 45px;
    line-height: 45px;
    color: #000;
    border: none;
    font-size: 12px;
    background-color: #F8E71C;
}

.offer-box-build-service .price .discount {
    background-color: #FF8800;
}

.offer-box .price .original {
    line-height: 19px;
    font-size: 14px;
    text-decoration: line-through;
}

.offer-box .price .installment-available {
    color: #fff;
    font-size: 12px;
    margin-bottom: 12px;
}

.goods-details .installment-available {
    color: #fff;
    margin-bottom: 10px;
}

.offer-full .free {
    margin-top: 50px;
}

.max-home .shopping .scroll-down {
    margin-left: -40px;
    width: 150px;
}

@media screen and (max-height: 800px) {
    .offer-full {
        margin-top: -240px;
    }
}

@media screen and (max-height: 730px) {
    .max-home .shopping .scroll-down {
        left: 25%;
    }
}

@media screen and (max-height: 650px) {
    .offer-full {
        margin-top: -225px;
    }
    .max-home .offer-full .shopping-content-item-header .title h2 {
        font-size: 29px;
        line-height: 32px;
    }
    .max-home .offer-full .shopping-content-item-header .title h5 {
        margin-top: 10px;
    }
    .offer-col-full-left {
        margin-top: 0px;
        width: 47%;
    }
    .offer-col-full-right {
        margin-top: 40px;
    }
    .offer-full-image-container {
        width: 662px;
        height: 370px;
    }
    .offer-full-image-container .free-gift-wrapper img {
        width: 125px;
        height: 125px;
    }
    .offer-full .free {
        margin-top: 20px !important;
        font-size: 16px;
    }
}

@media screen and (max-width: 1420px) {
    .offer-full {
        margin-top: -225px;
        width: 1220px;
        margin-left: -610px;
    }
    .max-home .offer-full .shopping-content-item-header .title h2 {
        font-size: 29px;
        line-height: 32px;
    }
    .max-home .offer-full .shopping-content-item-header .title h5 {
        margin-top: 10px;
    }
    .offer-col-full-left {
        margin-top: 25px;
        width: 41%;
    }
    .offer-col-full-right {
        margin-top: 40px;
    }
    .offer-col-full-right > div > div:last-child {
        margin-right: 0px;
    }
    .offer-box {
        width: 210px;
        margin-right: 10px;
    }
    .offer-full-image-container {
        width: 490px;
        height: 274px;
    }
    .offer-full-image-container .free-gift-wrapper {
        right: 50px;
    }
    .offer-full-image-container .free-gift-wrapper img {
        width: 125px;
        height: 125px;
    }
    .offer-full .free {
        margin-top: 20px !important;
        font-size: 16px;
    }
}

@media screen and (max-width: 1100px) {
    .offer-col-full-left, .offer-col-full-right {
        display: none;
    }
    .offer-full {
        margin-top: -320px;
        margin-left: 0px;
        left: auto;
        width: 100%;
    }
    .max-home .goods-pic-left {
        width: 500px;
        height: 280px;
    }
    .max-home .shopping .scroll-down {
        margin-left: -80px;
    }
    .mobile-shopping-item-details .installment-available {
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 2000px) {
    .offer-full {
        width: 1880px;
        margin-left: -940px;
    }
    .offer-col-full-left {
        width: 47%;
    }
    .offer-col-full-right {
        width: 49%;
    }
    .offer-full-image-container {
        height: 495px;
        width: 891px;
    }
    .offer-box {
        height: 440px;
        width: 280px;
        padding: 22px 20px 8px 20px;
    }
    .offer-box h3 {
        line-height: 35px;
        font-size: 29px;
    }
    .offer-box p {
        line-height: 29px;
        font-size: 22px;
        margin-top: 28px;
    }
    .offer-box .price .original {
        line-height: 29px;
        font-size: 21px;
    }
    .offer-box .price .discount {
        margin-top: 19px;
        line-height: 67px;
        height: 67px;
        font-size: 18px;
    }
    .offer-box-build-service img {
        height: 75px;
    }
}