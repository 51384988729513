.container-wrapper75 {
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: 80px;
}

.container-wrapper75.product-list-results .product-list-banner {
    margin-bottom: 50px;
    max-width: 100%;
    background-repeat: no-repeat;
	background-position: center center;
    background-size: contain;
    height: 300px;
}

.container-wrapper75.product-list-results h1 {
    color: #fff;
    text-align: center;
    margin-bottom: 50px;
}

.container-wrapper75 .row {
    display: flex;
    flex-wrap: wrap;
}

.container-wrapper75 .row .product-result {
    min-width: 31.33%;
    max-width: 31.33333%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 1;
    margin: 0.75%;
    margin-bottom: 5%;
}

.container-wrapper75 .row .product-result .product-thumb {
    width: 100%;
    height: 100%;
    border: thin solid transparent;
    padding-top: 55%;
    cursor: pointer;
    position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.container-wrapper75 .row .product-result .product-title-wrapper {
    margin-top: 12px;
    margin-bottom: 12px;
}

.container-wrapper75 .row .product-result .product-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    color: #fff;
}

.container-wrapper75 .row .product-result .product-desc {
    font-size: 16px;
    line-height: 19px;
    color: #999;
    margin-bottom: 12px;
}

.container-wrapper75 .row .product-result .product-price div {
    display: inline-block;
}

.container-wrapper75 .row .product-result .product-original-price {
    color: #999;
    font-size: 16px;
    line-height: 22px;
    vertical-align: middle;
    text-decoration: line-through;
    margin-right: 14px;
}

.container-wrapper75 .row .product-result .product-sale-price {
    color: #e83345;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    vertical-align: middle;
}

.container-wrapper75 .row .product-result .product-no-sale-price {
    color: #999;
    font-size: 20px;
    line-height: 22px;
    vertical-align: middle;
}

@media screen and (max-width: 700px) {
    .container-wrapper75 {
        margin-top: 50px;
        max-width: 90%;
    }

    .container-wrapper75.product-list-results .product-list-banner {
        height: 160px;
    }

    .container-wrapper75 .row .product-result {
        width: 100%;
        max-width: 100%;
        margin: 1.75%;
        margin-bottom: 5%;
    }

    .container-wrapper75 .row .product-result .product-title-wrapper {
        margin-top: 12px;
        margin-bottom: 6px;
    }

    .container-wrapper75 .row .product-result .product-title {
        font-size: 16px;
        line-height: 19px;
    }

    .container-wrapper75 .row .product-result .product-desc {
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 6px;
    }

    .container-wrapper75 .row .product-result .product-original-price {
        font-size: 11px;
        line-height: 13px;
        margin-right: 6px;
    }
    
    .container-wrapper75 .row .product-result .product-sale-price {
        font-size: 15px;
        line-height: 18px;
    }
}