.max-home .mobile{
	position: relative;
	width: 100%;
	height: 100%;
}
.max-home .home{
	position: relative;
	width: 100%;
	/* height: 100%; */
  z-index: 11;
}
/*video*/
.max-home .mobile-home-video{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.max-home .mobile-home-video>video{
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}
/*home-content*/
.max-home .mobile .home-content{
	position: relative;
	z-index: 0;
	padding: 86px 0 30px;
	width: 100%;
	/* margin-top: 4.28rem; */
	margin-left: 29px;
}
.max-home .home-content img{
	display: block;
	width: 100%;
	height: 100%;
}
.max-home .mobile .home-content-icon{
	margin-top: 48px;
	margin-left: 5px;
}
.max-home .mobile .home-content-icon>img:first-child{
	width: 170px;
	height: 78px;
	margin-bottom: 15px;
}
.max-home .mobile .home-content-icon>img:last-child {
	width: 85px;
	height: 12px;
	margin-bottom: 25px;
}
.max-home .home-lucasfilm-mobile {
	color: #FFF;
	font-size: 7px;
	margin-left: 5px;
	margin-bottom: 25px;
}
.max-home .mobile .home-content-button>img{
	margin-top: 9px;
	width: 100px;
	height: 20px;
	margin-bottom: 12px;
}
.max-home .mobile .home-content>p{
	margin-left: 5px;
	width: 263px;
	line-height: 27px;
	font-size: 25px;
	color: #FFFFFF;
}
.max-home .mobile .home-content-button{
	margin-top: 25px;
}
.max-home .mobile .home-content-button>button, .max-home .mobile .home-content-button>a{
	display: block;
	width: 138px;
	height: 41px;
	color: #000;
	border: none;
	font-size: 13px;
	font-weight: bold;
}
.max-home .mobile .home-content-button>a {
	text-align: center;
	line-height: 40px;
}
.max-home .mobile .home-content-button>button:first-child, .max-home .mobile .home-content-button>a:first-child{
	margin-bottom: 12px;
	background-color: #F8E71C;
}
.max-home .mobile .home-content-button>button:last-child, .max-home .mobile .home-content-button>a:last-child, .max-home .mobile .learn-more-btn{
	background-color: #FFFFFF;
}
.max-home .mobile .learn-more-btn {
	margin-bottom: 25px;
}

/*home-bottom*/
.max-home .home-bottom{
	width: 100%;
	position: absolute;
	/* margin-bottom: 12px; */
  bottom: 12px;
	left: 0;
}
.max-home .home-bottom>h3{
	font-size: 14px;
	color: #F8E71C;
	text-align: center;
	margin-bottom: 5px;
}
.max-home .home-bottom-arrow{
	margin-top: 7px;
	height: 24px;
	margin: 0 auto;
    text-align: center;
	position: relative;
	width: 100%;
}
.max-home .home-bottom-arrow>span{
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -3px;
	display: inline-block;
	width: 2px;
	height: 14px;
	background-color: #F8E71C;
}
.max-home .home-bottom-arrow>i{
	position: absolute;
	top: 14px;
	left: 50%;
	margin-left: -5px;
	border-color: #F8E71C;
    border-style: solid;
    border-width: 10px 3px 0;
    border-left-color: transparent;
    border-right-color: transparent;	
}


