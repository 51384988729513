.bg-white-wrapper .pc-language-choose {
    background-color: #000;
}

.bg-white {
    background-color: #fff;
    height: 100%;
    overflow: auto;
}

.product-details .content-wrapper {
    width: 1190px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    position: relative;
}

.product-details .content-wrapper .content {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 50px;
    padding-top: 0px;
}

.product-details .product-detail-banner {
    /* height: 30px; */
    padding: 5px;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 15px;
    background-color: #FFFACD;
    color: #000;
    text-align: center;
}

.product-details .product-detail-banner a {
    text-decoration: underline;
    color: #000;
}

.content-left {
    margin-right: 60px;
    width: 48%;
}

.content-right {
    width: 48%;
    padding-top: 30px;
}

.content-left .breadcrumb {
    margin-bottom: 15px;
}

.content-media-display {
    margin-bottom: 15px;
    border: thin solid #888;
    height: 314px;
    text-align: center;
}

.content-media-display > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-media-display .youtube-embed {
    height: 100% !important;
}

.content-media-thumbnails {
    display: flex;
    justify-content: flex-start;
}

.content-media-thumbnails > div {
    width: 18%;
    border: thin solid #888;
    margin-right: 2%;
    cursor: pointer;
    height: 56px;
    text-align: center;
}

.content-media-thumbnails > div:hover {
    border: thin solid #f8e71c;
}

.content-media-thumbnails > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-media-thumbnails .video-play-thumb {
    position: relative;
}

.content-media-thumbnails .video-play-icon {
    background-image: url("../image/common/video-play.png");
	background-repeat: no-repeat;
	background-position: center center;
    background-size: contain;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 50%;
    margin-left: -16px;
}

.product-details h1 {
    margin-bottom: 8px;
    font-weight: 600;
}

.product-details h2 {
    color: #888;
    margin-bottom: 20px;
}

.product-details h3 {
    color: #888;
    margin-bottom: 35px;
}

.product-details p.regular-price {
    font-size: 18px;
    line-height: 20px;
    text-decoration: line-through;
    margin-bottom: 20px;
}

.product-details p.sale-price {
    color: #e83345;
    font-weight: bold;
    font-size: 33px;
    line-height: 40px;
    margin-bottom: 20px;
}

.product-details p.estimated-delivery-date {
    margin-bottom: 20px;
    color: #888;
}

.product-details p.delivery-notice {
    margin-bottom: 20px;
    color: #888;
}

.product-details .btn-add-cart {
    width: 236px;
    height: 45px;
    font-size: 14px;
    color: #000;
    line-height: 45px;
    text-align: center;
    padding: 0px;
    cursor: pointer;
    text-transform: uppercase;
    background-color: #f8e71c;
    font-weight: bold;
    margin-bottom: 10px;
}

.content-plaque-container .btn-switch-plaque {
    width: 236px;
    height: 45px;
    font-size: 14px;
    color: #000;
    line-height: 40px;
    text-align: center;
    padding: 0px;
    cursor: pointer;
    text-transform: uppercase;
    background-color: #fff;
    border: 3px solid #000;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 0px;
}

.content-plaque-container {
    margin-bottom: 10px;
}

.content-plaque-container .plaque-heading {
    color: #000;
}

.content-plaque-container .plaque-note {
    color: #555;
    font-style: italic;
}

.plaque-name-container {
    margin-bottom: 10px;
}
  
.plaque-name-container input {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    padding-left: 0;
    font-size: 16px;
    flex: 1 1;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}

.custom-credit-line-container {
	position: relative;
	display: inline-block;
	margin-top: 0px;
	width: 360px;
	margin-right: 20px;
	margin-bottom: 30px;
}

.custom-credit-line-container input {
	flex: 1;
	width: 360px;
	border-bottom: 1px solid #000;
	padding-bottom: 10px;
	padding-left: 0;
    font-size: 16px;
}

.choose-credit-line {
	position: absolute;
	bottom: -100px;
	/* right: 0; */
	width: 350px;
	border: 1px solid #000;
}

.choose-credit-line div {
	position: relative;
	z-index: 11;
	padding-left: 18px;
	height: 44px;
	line-height: 44px;
	font-size: 14px;
	color: #000;
	background-color: #fff;
}

.choose-credit-line div:hover {
	background-color: #D8D8D8;
}

@media screen and (max-width: 1220px) {
    .product-details .content-wrapper {
        width: 990px;
    }
    .content-media-display {
        height: 258px;
    }
    .content-media-thumbnails > div {
        height: 47px;
    }
}

@media screen and (max-height: 880px) {
    .product-details .content-wrapper .content {
        padding-top: 0px;
    }
}

@media screen and (max-width: 1024px) {
    .product-details .content-wrapper {
        width: 100%;
    }
    .product-details .content-wrapper .content {
        display: block;
        padding-top: 0px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .content-left {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .content-right {
        width: 100%;
        padding-top: 0px;
    }
    .content-left .breadcrumb {
        display: none;
    }
    .content-media-display {
        height: auto;
    }
    .content-media-thumbnails > div {
        height: auto;
    }
    .product-details h1 {
        font-size: 26px;
    }
    
    .product-details h2 {
        font-size: 17px;
        margin-bottom: 15px;
    }
    
    .product-details h3 {
        font-size: 16px;
        margin-bottom: 25px;
    }
    
    .product-details p.regular-price {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 20px;
    }
    
    .product-details p.sale-price {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 15px;
    }
    .product-details .btn-add-cart {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        margin-bottom: 0px;
    }
    .content-plaque-container .btn-switch-plaque {
        /* position: fixed;
        bottom: 0; */
        width: 100%;
        /* left: 0;
        right: 0; */
        margin-bottom: 10px;
    }

    .content-plaque-container .plaque-heading-1 {
        color: #000;
        margin-top: 30px;
    }

    .custom-credit-line-container {
        position: relative;
        display: inline-block;
        margin-top: 0px;
        width: 100%;
        margin-right: 20px;
        margin-bottom: 30px;
    }

    .custom-credit-line-container input {
        flex: 1;
        width: 100%;
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
        padding-left: 0;
        font-size: 14px;
    }

    .choose-credit-line {
        position: absolute;
        /* bottom: -100px; */
        /* right: 0; */
        width: 100%;
        border: 1px solid #000;
    }
    
    .choose-credit-line div {
        position: relative;
        z-index: 11;
        padding-left: 18px;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        color: #000;
        background-color: #fff;
    }
    
    .choose-credit-line div:hover {
        background-color: #D8D8D8;
    }
}

@media screen and (max-width: 600px) {
    .product-details .content-wrapper .content {
        padding-top: 30px;
    }
    .content-media-thumbnails .video-play-icon {
        width: 18px;
        height: 18px;
        margin-top: -9px;
        margin-left: -9px;
    }
}