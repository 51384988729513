.max-home .mobile-model3{
	width: 100%;
	height: 100%;
}
.max-home .mobile-model3-video{
	padding-top: 59px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}

.max-home .model3-video1-info{
	/*overflow: hidden;*/
	position: absolute;
	top: 50px;
	/*left: 155px;*/
	height: 134px;
	line-height: 100px;
	z-index: 11;	
}

.max-home .model3-video2-info{
	/*overflow: hidden;*/
	position: absolute;
	top: 50px;
	/*left: 155px;*/
	height: 134px;
	line-height: 100px;
	z-index: 11;	
}

@media screen and (min-width: 2000px){
	.max-home .model3-video1-info>.icon{
		float: left;
		width: 100px;
		background-color: #000;
	}

	.max-home .model3-video1-info>div>img{	
		display: block;
		margin: 20px 16.5px;
		width: 59px;
		padding-bottom: 33px;
	}
	
	.max-home .model3-video1-info>p{
		float: left;
		margin-top: 32px;
		margin-left: 0px;
		line-height: 32px;
		font-size: 27px;
		color: #fff;
	}

	.max-home .model3-video2-info>.icon{
		float: left;
		width: 100px;
		background-color: #000;
	}

	.max-home .model3-video2-info>div>img{	
		display: block;
		margin: 20px 16.5px;
		width: 59px;
		padding-bottom: 33px;
	}
	
	.max-home .model3-video2-info>p{
		float: left;
		margin-top: 32px;
		margin-left: 0px;
		line-height: 32px;
		font-size: 27px;
		color: #fff;
	}
}

@media screen and (max-width: 2000px){
	.max-home .model3-video1-info>.icon{
		float: left;
		width: 85px;
		background-color: #000;
	}

	.max-home .model3-video1-info>div>img{	
		display: block;
		margin: 34px 16.5px;
		width: 50px;
		padding-bottom: 17px;
	}

	.max-home .model3-video1-info>p{
		float: left;
		margin-top: 49px;
		margin-left: 0px;
		line-height: 24px;
		font-size: 20px;
		color: #fff;
	}

	.max-home .model3-video2-info>.icon{
		float: left;
		width: 85px;
		background-color: #000;
	}

	.max-home .model3-video2-info>div>img{	
		display: block;
		margin: 34px 16.5px;
		width: 50px;
		padding-bottom: 17px;
	}

	.max-home .model3-video2-info>p{
		float: left;
		margin-top: 49px;
		margin-left: 0px;
		line-height: 24px;
		font-size: 20px;
		color: #fff;
	}

	.max-home .model5-video-adjust>p {
		margin-top: 36px;
	}
}

.max-home .model3-video2-info{
	/*overflow: hidden;*/
	/*right: 155px;*/
}
.max-home .model3-video2-info>div{
	float: right;
}
.max-home .model3-video2-info>p{
	float: right;
	text-align: right;
}
.max-home .model4-video1-info>div>img{
	margin-top: 54px;
	width: 63px;
}
.max-home .model4-video-adjust>.icon {
	margin-right: 10px;
}
.max-home .model4-video2-info{
	/*right: 142px;*/
}
.max-home .model4-video2-info>div>img{
	margin: 46px auto;
	width: 49px;
}
.max-home .model5-video2-info{
	right: 142px;
}
.max-home .model5-video2-info>div>img{
	margin: 44px auto;
	width: 45px;
}
.max-home .model5-video1-info>div>img{
	margin: 34px auto;
}

.mobile-model3-video-title{
	position: relative;
	z-index: 1;
	margin-top: 25px;
	line-height: 28px;
	font-size: 24px;
	text-align: center;
	color: #fff;
}
.mobile-model3-video-info{
	position: absolute;
	left: 50%;
	margin-left: -130px;
	z-index: 1;
}
.mobile-model3-video-info>div{
	float: left;
	margin-right: 5px;
}
.mobile-model4-video-info>div {
	margin-top: 8px;
}
.mobile-model3-video-info>div>img{
	width: 38px;
	height: 38px;
}
.mobile-model3-video-info.mobile-model4-video-info>div>img{
	width: 51px;
	height: 26px;
}
.mobile-model3-video-info>p{
	margin-top: 3px;
	margin-left: 7px;
	float: left;
	line-height: 17px;
	font-size: 13px;
	color: #fff;
	width: 200px;
}
.mobile-model3-video-info .mobile-model3-video-details{
	line-height: 35px;
}
.mobile-model3-video>video{
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
}
.mobile-model31-pic>img,.mobile-model31-pic>.video-hold>video{
	position: absolute;
	height: 100%;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	padding-top: 55px;
	box-sizing: border-box;
	z-index: 0;	
}
.mobile-model3-video .mobile-model31-video>.video-hold>video {
	left: 350px;
}

#mobile-model3-canvas,#mobile-model31-canvas,#mobile-model4-canvas,#mobile-model41-canvas,#mobile-model5-canvas,
#mobile-model51-canvas,#mobile-model52-canvas,#mobile-model53-canvas{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 11;
}

.canvas-line{
	border-width: 0 0 1px;
    height: 114px;
    min-width: 100%;
    position: absolute;
    top: 0px;
    z-index: 11111;
    border-style: solid;
    border-color: #fff;
}
.canvas-line>div{
	width: 80px;
	height: 1px;
	background-color: #fff;
	position: absolute;
	bottom: -1px;
	transform: rotate(45deg) translate3d(68px,-27.5px,0px);
}
.canvas-line-right>div{
	transform: rotate(-45deg) translate3d(-68px,-27.5px,0px);
}
.model3-video1-info{
	/* display: none; */
}

#model3-video-info1, #model4-video-info1, #model5-video-info1{
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	-ms-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out;
}

#model3-video-info2,#model4-video-info2, #model5-video-info2{
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	-ms-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out;
}

.show{
	visibility: visible;
}

.up{
	-ms-transform: translate(0px, -80px); /* IE 9 */
	-webkit-transform: translate(0px, -80px); /* Safari */
	transform: translate(0px, -80px);
	-webkit-transition: -webkit-transform 2s; /* Safari */
	transition: transform 2s;
}