.max-home .mobile-guide{
	position: relative;
	width: 100%;
	/* height: 47.64rem; */
}
.max-home .mobile-guide-slide1{
	position: relative;
	padding-top: 5.42rem;
}
.max-home .mobile-guide-slide2{
	position: relative;
	/*padding-top: 7.92rem;*/
}
.max-home .mobile-guide-slide1-inner{
	position: relative;
	width: 100%;
	height: 25rem;
}
.max-home .mobile-guide-slide2-inner{
	position: relative;
	width: 100%;
	height: 13.28rem;
}
.max-home .mobile-guide-slide-list{
	position: relative;
	margin: 0 auto;
	width: 20.28rem;
	height: 100%;
}
.max-home .mobile-guide-slide-list>li{
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.max-home .mobile-guide-slide-list>li>img{
	width: 100%;
	height: 100%;
}
.max-home .mobile-guide-slide-controls{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.max-home .mobile-guide-slide-controls>a{
	position: absolute;
	top: 50%;
	margin-top: -1.12rem;
	display: block;
	width: 1rem;
	height: 2.14rem;
}
.max-home .mobile-guide-slide-controls>a:first-child{
	left: 1.14rem;
}
.max-home .mobile-guide-slide-controls>a:last-child{
	right: 1.14rem;
}
.max-home .mobile-guide-slide-controls>a>img{
	width: 100%;
	height: 100%;
}

.max-home .mobile-guide1-title{
	margin: 0 auto 21px auto;
	width: 300px;
	padding-top: 75px;
}
.max-home .mobile-guide2-title{
	margin: 2.64rem auto 1.35rem;
}
.max-home .mobile-guide1-title>h2{
	text-align: center;
	line-height: 31px;
	font-size: 25px;
 	color: #fff;
}
.max-home .mobile-guide1-info{
	margin: 0 auto;
	width: 280px;
	/*height: 12.21rem;*/
}
.max-home .mobile-guide1-info>p{
	line-height: 18px;
	font-size: 16px;
	color: #9B9B9B;
	margin-top: 18px;
}
.max-home .mobile-guide1-info>p:last-child{
	margin-top: 18px;
}
.max-home .mobile-guide1-info>p.guide-footnote{
	color: #fff;
	font-weight: bold;
	font-size: 14px;
}