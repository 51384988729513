.max-home .mobile-shopping{

}
.max-home .mobile-shopping-item{
	/* margin-bottom: 3.21rem; */
}
.max-home .mobile-shopping-item-pic{
	width: 100%;
	height: 14.78rem;
	/* margin-top: -23px; */
	position: relative;
}
.max-home .mobile-shopping-item-pic .free-gift-wrapper {
	position: absolute;
	bottom: 16px;
	right: 28px;
	z-index: 2;
	cursor: pointer;
}
.max-home .mobile-shopping-item-pic .free-gift-wrapper img {
	width: 92px;
	height: 92px;
}
.max-home .mobile-shopping-item-pic>img{
	width: 100%;
	height: 100%;
}
.max-home .mobile-shopping-item-details{
	padding:0 20px;
	display: block;
}
.max-home .mobile-shopping-item-title{
	margin: 0px auto 26px;
	color: #fff;
	padding: 62px 29px 0;
}
.max-home .mobile-shopping-item-title>h1{
	/*width: 14.64rem;*/
	/*height: 5.14rem;*/
	font-size: 25px;
	line-height: 30px;
	text-align: center;
}
.max-home .mobile-shopping-item-title>h4{
	margin-top: 4px;
	/*width: 14.21rem;*/
	font-size: 12px;
	line-height: 16px;
	text-align: center;
}
.max-home .mobile-shopping-kit {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;
	flex-basis: 50%;
	border: 1px solid #555;
}
.max-home .mobile-shopping-kit>p.desc1 {
	height: auto;
}
.max-home .mobile-shopping-kit>p.desc1a {
	height: auto;
}
.max-home .mobile-shopping-kit>p.desc2a {
	height: 17px;
}
.max-home .mobile-shopping-kit .bold {
	font-weight: bold;
}
.max-home .mobile-shopping-kit>h2{
	margin: 0;
	/*width: 13.92rem;*/
	line-height: 18px;
	color: #F8E71C;
	font-size: 16px;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 12px;
	margin-top: 13px;
}
.max-home .mobile-shopping-kit>h5{
	margin-top: 0.57rem ;
	width: 12.5rem;
	/*height: 4.07rem;*/
	line-height: 1.35rem;
	color: #F8E71C;
}
.max-home .mobile-shopping-kit>p{
	margin-top: 5px;
	margin-bottom: 14px;
	/*width: 17.71rem;*/
	color: #F8E71C;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}
.max-home .mobile-shopping-kit>p.strike{
	text-decoration: line-through;
}
.max-home .mobile-shopping-kit button{
	/*width: 14.5rem;*/
	height: 39px;
	line-height: 39px;
	text-align: center;
	color: #000;
	border: none;
	background-color: #F8E71C;
	font-size: 14px;
	font-weight: bold;
	margin-left: auto;
	margin-right: auto;
	display: block;
	width: 100%;
}
.max-home .mobile-shopping-stk{
	/* border-left: 1px solid #555; */
}
.max-home .mobile-shopping-stk-icon{
	width: 116px;
	height: 40px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}
.max-home .mobile-shopping-stk-icon>img{
	width: 100%;
	height: 100%;
	margin-top: 10px;
}
.max-home .mobile-shopping-stk>h5{
	color: #FF8800;
}
.max-home .mobile-shopping-stk>p{
	color: #FF8800;
}
.max-home .mobile-shopping-stk>button{
	background-color: #FF8800;
}
.max-home .mobile-stk-info{
	margin-left: 2.14rem;
	margin-bottom: 1rem;
	width: 21.57rem;
	padding-top: 5.5rem;
	text-align: center;
}
.max-home .mobile-stk-info>p{
	line-height: 1.35rem;
	font-size: 1.14rem;
	color: #fff;
}
.max-home .mobile-stk-info>p:nth-child(3){
	margin-bottom: 1rem;
}
.max-home .mobile-stk-info>p>a{
	
}
.max-home .mobile-stk-info>p>span{
	color: #F8E71C;
}
.max-home .mobile-Brand-links{
	/* margin-left: 2.14rem; */
	margin-left: auto;
	margin-right: auto;
	width: 15rem;
}
.max-home .mobile-head{
	overflow: hidden;
}
.max-home .mobile-head>img{
	float: left;
	margin-right: 1.5rem;
	display: block;
	width: 1.57rem;
	height: 1.357rem;
}
.max-home .mobile-head>img:last-child {
	margin-right: 0px;
}
.max-home .mobile-Brand{
	overflow: hidden;
	margin-top: 1.64rem;
	width: 14.86rem;
}
.max-home .mobile-Brand>img{
	margin-right: 0.57rem;
	margin-bottom: 0.64rem;
	display: block;
	float: left;
	width: 3.14rem;
	height: 1.71rem;
}
.max-home .mobile-copyright{
	margin:1rem auto 0px auto;
	width: 15.86rem;
}
.max-home .mobile-copyright>p{
	line-height: 1.07rem;
	font-size: 0.857rem;
	color: #fff;
	text-align: center;
}


.free{
  margin: 33px 0 36px;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  text-align:center;
  color: #999;
}
.max-home .free .strengthen {
	font-style: normal;
	color: #FFF;
}
.mobileFree{
  margin:0;
  padding: 35px 50px 0 50px;
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  text-align:center;
  color: #999;
}
.mobileFree .strengthen {
	font-style: normal;
	color: #FFFFFF;
	font-weight: normal;
}
 @media screen and (min-width: 1500px){
   .free{
      font-size: 18px;
      line-height: 21px;
    }
 }

 .max-home .mobile-footer {
	 margin-top: 80px;
 }

 .max-home .mobile-footer .footer-questions {
	 margin-bottom: 80px;
 }

 .max-home .mobile-footer .footer-questions-block {
	 text-align: center;
 }

 .max-home .mobile-footer .footer-newsletter-sign-up {
	position: relative;
	padding-top: 40px;
	padding-bottom: 40px;
	border: 1px solid #287198;
	margin-left: 10px;
	margin-right: 10px;
	box-shadow: 0px 0px 20px #287198 inset;
 }

 .max-home .mobile-footer .footer-newsletter-sign-up:before {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0.3;
	background-image: url('../image/gallery/Position_10m.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

 .max-home .mobile-footer .footer-newsletter-heading {
	z-index: 2;
	position: relative;
	color: #fff;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 20px;
	padding: 0px 20px;
	text-align: center;
 }

 .max-home .mobile-footer .footer-newsletter-heading2 {
	z-index: 2;
	position: relative;
	color: #fff;
	font-size: 17px;
	margin-bottom: 20px;
	padding: 0px 20px;
	text-align: center;
 }

 .max-home .mobile-footer .footer-newsletter-sign-up-box {
	z-index: 2;
	position: relative;
	 text-align: center;
 }

 .max-home .mobile-footer .footer-newsletter-sign-up-box input {
	background-color: transparent;
	border: 1px solid #FFF;
	padding: 10px 10px;
	color: #FFF;
	font-size: 15px;
	margin-bottom: 10px;
	margin-right: 10px;
	font-family: "Futura" !important;
	border-radius: 0px;
}

.max-home .mobile-footer .footer-newsletter-sign-up-box input[type=submit] {
	text-transform: uppercase;
	font-weight: bold;
	background-color: #f7e53b;
	color: #000;
	border: 1px solid #f7e53b;
	-webkit-appearance: none;
	border-radius: 0px;
	font-size: 14px;
}

.max-home .mobile-footer .footer-newsletter-sign-up-box input::placeholder {
	color: #CCC;
}

.max-home .mobile-footer .footer-newsletter-fineprint {
	z-index: 2;
	position: relative;
	color: #CCC;
	font-size: 12px;
	padding: 0px 20px;
	text-align: center;
}

.max-home .mobile-footer .footer-questions-heading {
	color: #fff;
	font-size: 22px;
	margin-bottom: 20px;
	padding: 0px 20px;
}

.max-home .mobile-footer .footer-questions-content {
	color: #fff;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 10px;
	padding: 0px 20px;
}

.max-home .mobile-footer .footer-questions-block {
	margin-bottom: 30px;
}

.max-home .mobile-footer .footer-social img {
	height: 30px;
	margin-right: 20px;
}

.max-home .mobile-footer .footer-social a:first-child {
	margin-left: 20px;
}

.max-home .mobile-footer .mobile-stk-copyright {
	z-index: 2;
	position: relative;
	margin-top: 30px;
}

.max-home .mobile-footer .mobile-stk-copyright p {
	line-height: 16px;
	font-size: 14px;
	color: #fff;
	text-align: center;
	padding: 0px 20px;
	margin-bottom: 5px;
}

.max-home .mobile-footer .mobile-stk-copyright p a {
	color: #fff;
	text-decoration: underline;
}

.max-home .mobile-footer .mobile-stk-copyright p span {
	margin: 0px 6px;
}
