.max-home .mobile-box-countdown{
	padding-top: 4.28rem;
	
	
}
.max-home .mobile-box-countdown>div{
	height: 4.28rem;	
	background-color:#1A1A1A ;
}
.max-home .mobile-box-countdown h2{
	text-align: center;
	line-height: 1.43rem;
	font-size: 0.93rem;
	color: #F8E71C;
}
.max-home .mobile-time-list{
	font-size: 0;
	text-align: center;
	list-style: none;
}
.max-home .mobile-time-list>li{
	display: inline-block;
	line-height: 1.64rem;
	color: #fff;
}
.max-home .mobile-time-list>li>span:first-child{
	font-size: 1.36rem;
}
.max-home .mobile-time-list>li>span:last-child{
	margin: 0 0.3rem;
	font-size: 0.8rem;
}
.max-home .mobile-box-video{
	width: 100%;
	height: 18.357rem;
	position: absolute;
	margin-top: 115px;
	opacity: 0.5;
	/*background: url('../video/box-pic.jpg') no-repeat;*/
	background-size: cover;
	background-position: 105px 0px;
}
.max-home .mobile-box-video>img{
	width: 100%;
	height: 100%;
}
.max-home .mobile-box-details{
	position: relative;
	padding-left: 30px;
	padding-top: 90px;
}
.max-home .mobile-box-details-title{
	padding: 0 0 14px;
}
.max-home .mobile-box-details-title>h1{
	width: 312px;
	line-height: 28px;
	font-size: 25px;
	color: #fff;
}
.max-home .mobile-box-details>h4{
	width: 322px;
	line-height: 22px;
	font-size: 12.5px;
	color: #fff;
}
.max-home .mobile-box-details>p{
	margin: 14px 0 14px;
	line-height: 19px;
	font-size: 16px;
	color: #F8E71C;
	text-decoration: line-through;
}
.max-home .mobile-box-details>button, .max-home .mobile-box-details>a{
	width: 215px;
	height: 44px;
	line-height: 44px;
	border: none;
	text-align: center;
	background-color: #F8E71C;
	font-weight: bold;
}
.max-home .mobile-box-details>a {
	display: block;
	line-height: 43px;
	color: #000;
}
.max-home .mobile-box-details>h5{
	margin: 15px 0 22px;
	line-height: 21px;
	font-size: 14px;
	color: #fff;
}
.max-home .mobile-box-build>img{
	display: block;
	width: 122px;
	height: 45px;
}
.max-home .mobile-box-build>p{
	margin: 6px 0 13px;
	line-height: 19px;
	font-size: 16px;
	color: #FF8800;
}
.max-home .mobile-box-details>span{
	line-height: 21px;
	font-size: 14px;
	color: #9B9B9B;
}
.max-home .box-bottom{
	position: relative;
	margin: 1.35rem 0 1.07rem;
	height: 1.71rem;
}
