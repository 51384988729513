.mobile-model5-pic{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.mobile-model5-pic>img,.mobile-model5-pic>video{
	position: absolute;
	height: 100%;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	padding-top: 55px;
	box-sizing: border-box;
	z-index: 0;	
}
.mobile-model41-video>video {
	left: 0px;
}
.mobile-model5-video>video {
	top: 30px;
}
.mobile-model51-video>video {
	height: 75%;
	top: 18%;
}
.mobile-model3-video .mobile-model53-video>video {
	/*left: 360px;*/
	height: 75%;
	top: 18%;
	left: 290px;
}