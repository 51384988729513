.gallery{
  width: 100%;
  /*height: 1320px;*/
}
.gallery-video{
  position: relative;
  margin: 0 auto;
}
.gallery-video>.slider{
  display: none;
  position: absolute;
  z-index: 111;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.gallery-video>.slider>.left-arrow, .gallery-video>.slider>.right-arrow{
  cursor: pointer;
}
.gallery-video>div>div{
  float: left;
}
.gallery-video>div>div:nth-child(2n){
  margin-left: 11px
}
.gallery-video>div>div:nth-child(1){
  margin-bottom: 11px
}
.gallery-video>div>div:nth-child(2){
  margin-bottom: 11px
}
.gallery-video>div>div>div{
  position: relative; 
}
.gallery-video>div>div>div video, .gallery-video>div>div>div img, .gallery-video>div>div img, .gallery-video>div>div>div div{
  width: 100%;
  height: 100%;
}
.gallery-video>div>div>div div{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    opacity: 0.7;
    border: 1px solid #287198;
    -webkit-box-shadow:0px 0px 20px #287198 inset;
    -moz-box-shadow:0px 0px 20px #287198 inset;
    box-shadow:0px 0px 20px #287198 inset;
    cursor: pointer;
}
.gallery-video>div>div>div div:hover{
  opacity: 1
}
.gallery-video>div>div>div:hover {
  /*filter: brightness(150%);*/
}
.gallery-video>div>.item>div{
  float: left;
}
.gallery-video .item>div:nth-child(2n){
  margin-left: 11px
}
.gallery-video .item>div:nth-child(1){
  margin-bottom: 11px
}
.gallery-video .item>div:nth-child(2){
  margin-bottom: 11px
}
.gallery-video>.slider>.slider-item{
  width: 75%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  float: none;
  cursor: pointer;
}
.gallery-video>.slider>.lider-item img{
 display: none
}

#gallery-top {
  width: 100%;
}

/*
@media screen and (max-width: 1300px){
  .gallery{
    height: 1250px;
  }
}
@media screen and (max-width: 1200px){
  .gallery{
    height: 1500px;
  }
}
@media screen and (min-width: 1400px){
  .gallery{
    height: 1370px;
  }
}
@media screen and (min-width: 1500px){
  .gallery{
    height: 1420px;
  }
}
@media screen and (min-width: 1600px){
  .gallery{
    height: 1470px;
  }
}
@media screen and (min-width: 1700px){
  .gallery{
    height: 1520px;
  }
}
@media screen and (min-width: 2000px){
  .gallery{
    height: 1590px;
  }
}
@media screen and (min-width: 1900px){
  .gallery{
    height: 1650px;
  }
}
@media screen and (min-width: 2000px){
  .gallery{
    height: 1750px;
  }
}
@media screen and (min-width: 2300px){
  .gallery{
    height: 1850px;
  }
}
*/


/* mobile 端 */
.mobile-gallery-pic{
  width: 100%;
  padding: 54px 11px 0px;
}
.mobile-gallery-pic h1{
  text-align: center;
  color: #FFF;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 20px;
}
.mobile-gallery-pic>div{
  position: relative;
  width: 100%;
  height: 220px;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.mobile-gallery-pic>div>.glow{
    width: 100%;
    height: 100%;;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    opacity: 1;
    border: 1px solid #287198;
    -webkit-box-shadow:0px 0px 20px #287198 inset;
    -moz-box-shadow:0px 0px 20px #287198 inset;
    box-shadow:0px 0px 20px #287198 inset;
}
.mobile-gallery-pic>div>.glow>a>.btn-glow {
  width: 100%;
  height: 100%;
}
.mobile-gallery-pic>div>img{
  width: 100%;
  height: 100%;
}
.loadMore{
  margin: 0 auto 4.1428rem;
  width: 22.5rem;
  height: 3.2142rem;
  border: 1px solid #fff;
}
.loadMore>h2{
  margin: 0.9285rem auto;
  width: 18.1428rem;
  height: 1.3571rem;
  line-height: 1.3571rem;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  font-weight: bold;
}