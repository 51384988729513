.max-home .mobile-video{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.max-home .mobile-video .video-button {
	background-image: url("../video/video.jpeg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.max-home .mobile-video>video{
	position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translate3d(-50%,0,0);
}
.max-home .mobile-video .video-hide{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	display: none;
  z-index: 11;
}
.max-home .mobile-video .video-hide>.x{
	position: absolute;
	top: 2rem;
	right: 1.42rem;
	width: 1.42rem;
	height: 1.42rem;
  z-index: 11;
}
.max-home .mobile-video .video-hide>.x>span{
	display: block;
	height: 2px;
	width: 1.6rem;
	background-color: #fff;
	border-radius: 1px;
	margin-bottom: -2px;
}
.max-home .mobile-video .video-hide>.x>span:first-child{
	transform: rotate(45deg);
}
.max-home .mobile-video .video-hide>.x>span:last-child{
	transform: rotate(-45deg);
}
.max-home .mobile-video .video-hide>video{
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width:100%;
	height:auto;
	z-index:1;
}
.max-home .progressTimeMobile{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
	z-index: 11;
	display: none;
}
.max-home .progressTimeMobile .progress{
  margin: 0 auto;
  width: 80%;
  height: 1px;
  /* background-color: #999; */
  border-radius: 0.5px;
}
.max-home .progressTimeMobile .progress>div{
  width: 0;
  height: 1px;
  background-color:yellow;
  transition: all 0.9s;
}
.max-home .progressTimeMobile .console{
  padding: 1.071rem 0;
  text-align: center;
}
.max-home .progressTimeMobile .console>img{
  width: 1.785rem;
  height: 1.785rem;
}
.max-home .progressTimeMobile .console .videoPlay{
  margin: 0 1.428rem;
  display: inline-block;
  width: 3.571rem;
  height: 3.571rem;
  line-height: 3.571rem;
}
.max-home .progressTimeMobile .console .videoPlay>img:first-child{
  width: 100%;
  height: 100%;
}