.instructions{
  width: 100%;

}

.inst-video {
  margin-left: auto;
  margin-right: auto;
}

.inst-video-holder {
  position: relative;
  border: thin solid #777;
}

.inst-video-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  color: #777;
}

.inst-video-item h3 {
  color: #fff;
}

.inst-video-item a {
  color: #fff;
  text-decoration: underline;
}

@media screen and (max-width: 1024px){
  .inst-video {
    width: 100%;
  }
  
  .inst-video-row {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0px;
  }
  
  .inst-video-holder {
    width: 100%;
    height: 393px;
    margin-bottom: 10px;
  }

  .inst-video-holder-filled {
    height: auto;
  }

  .youtube-embed {
    padding-top: 25px;
    padding-bottom: 56.25%;
  }
  
  .inst-video-text {
    font-size: 19px;
    margin-top: -10px;
    margin-left: -55px;
  }
  
  .inst-video-item h3 {
    font-size: 16px;
    line-height: 20px;
  }

  .inst-video-item {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1024px){
  .inst-video {
    width: 960px;
  }
  
  .inst-video-row {
    width: 100%;
    display: flex;
    margin-bottom: 48px;
    justify-content: space-around;
  }
  
  .inst-video-holder {
    width: 305px;
    height: 172px;
    margin-bottom: 10px;
  }

  .youtube-embed {
    padding-top: 170px;
  }
  
  .inst-video-text {
    font-size: 19px;
    margin-top: -10px;
    margin-left: -55px;
  }
  
  .inst-video-item h3 {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: 1300px){
  .inst-video {
    width: 1200px;
  }
  
  .inst-video-row {
    width: 100%;
    display: flex;
    margin-bottom: 60px;
    justify-content: space-around;
  }
  
  .inst-video-holder {
    width: 382px;
    height: 215px;
    margin-bottom: 13px;
  }

  .youtube-embed {
    padding-top: 213px;
  }
  
  .inst-video-text {
    font-size: 24px;
    margin-top: -13px;
    margin-left: -70px;
  }
  
  .inst-video-item h3 {
    font-size: 20px;
    line-height: 26px;
  }
}

@media screen and (min-width: 2000px){
  .inst-video {
    width: 1760px;
  }
  
  .inst-video-row {
    width: 100%;
    display: flex;
    margin-bottom: 88px;
    justify-content: space-around;
  }
  
  .inst-video-holder {
    width: 561px;
    height: 316px;
    margin-bottom: 19px;
  }

  .youtube-embed {
    padding-top: 314px;
  }
  
  .inst-video-text {
    font-size: 35px;
    margin-top: -23px;
    margin-left: -110px;
  }
  
  .inst-video-item h3 {
    font-size: 29px;
    line-height: 38px;
  }
}

/* molide 端 */
.molide-inst-video{
  width: 100%;
  padding:6.1428rem 1.5rem;
}
.molide-inst-video>div{
  width: 100%;
  color:#fff;
}
.molide-inst-video>div>div{
  position: relative;
  width:100%;
  height: 14.8571rem;
}
.molide-inst-video>div>div .playButtom{
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  width:4.3571rem;
  height: 2.5714rem;
}
.molide-inst-video>div>h3{
  margin:0.8571rem 0 0.5714rem;
  width: 100%;
  line-height: 1.8571rem;
  font-size: 1.2857rem;
}

.mobile-instructions-pack {
  margin-bottom: 20px;
}
.mobile-instructions-pack a {
  color: #fff;
  text-decoration: underline;
}