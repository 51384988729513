.tc{
  margin: 0 auto;
}
.tc h2{
  margin: 20px 0 36px;
  line-height: 32px;
  font-size: 28px;
  color: #FFF;
  text-align: center;
}
.tc .content{
  width: 100%; 
}
.tc .content>div>p{
  margin-bottom: 20px;
  font-size: 14px;
  color:#999;
  line-height: 16px;
}
.tc .content>div>h3>.policytitle{
  text-decoration: underline;
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 20px;
}
.tc .content>div>.policyindent {
  margin-bottom: 20px;
}
.tc .content>div>.policyindent>p {
  color: #999;
  margin-left: 20px;
}
.tc .content>div>h3{
  font-size: 16px;
  color: #fff;
  margin-bottom: 5px;
}
.tc .content>div>p>span{
  /*display: block;*/
  /*font-size: 14px;*/
}
 @media screen and (min-width: 1250px){
    .tc .title{
      line-height: 40px;
      font-size: 36px;
    }
    .tc .content>div>p{
       font-size: 18px;
        line-height: 20px;
        margin-bottom: 25px;
    }
    .tc .content>div>h3{
      font-size: 20px;
      margin-bottom: 8px;
    }
    .tc .content>div>.policyindent>p {
      font-size: 18px;
      line-height: 22px;
  }
 }

 @media screen and (min-width: 2000px){
    .tc .title{
        margin: 30px 0 54px;
        line-height: 63px;
        font-size: 54px;
    }
    .tc .content>div>p{
        font-size: 27px;
        line-height: 30px;
        margin-bottom: 38px;
    }
    .tc .content>div>h3{
        font-size: 30px;
        margin-bottom: 11px;
    }
    .tc .content>div>.policyindent>p {
        font-size: 27px;
        line-height: 32px;
    }
 }