.max-home .mobile-nav{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	/*width: 100%;
	height: 100%;*/
	z-index: 111;
}
/*.home{
	position: relative;
	width: 100%;
	height: 100%;
}*/
/*menu*/
.max-home .menu{
	position: absolute;
	top: 0;
	left: -100%;
	z-index: 11;
	width: 100%;
	height: 100%;
	color: #FFFFFF;
	background-color: #000000;
	transition: all 0.3s; 
}
.max-home .menu-title{	
	width: 100%;
	height: 57px;
	line-height: 57px;
	border-bottom-color: #FFFFFF;
	border-bottom-style: solid;
	border-bottom-width: 2px;
}
.max-home .menu-title>h3{
	padding-left: 29px;
	font-size: 16px;
}
.max-home .menu-shut{
	position: absolute;
	top: 20px;
	right: 20px;
	width: 17px;
	height: 17px;
	cursor: pointer;
}
.max-home .menu-shut>i{
	position: absolute;
    display: inline-block;
    width: 2px;
    height: 22px;
    top: -2px;
    background-color: #fff;
    left: 50%;
}
.max-home .menu-shut>i:first-child{
	transform: rotate(45deg);
}
.max-home .menu-shut>i:last-child{
	transform: rotate(-45deg);
}
.max-home .menu-list{
	padding: 39px 0 0 29px;
	list-style: none;
	background: #000;
}
.max-home .menu-list>li{
	margin-bottom: 24px;
	line-height: 20px;
	font-size: 17px;
	color: #fff;
}
.max-home .menu-list>li>span{
	cursor: pointer;
}
.max-home .mobile-nav .menu-list>li a{
	color: #fff;
}
#mainHeader .menu-list>li a{
	color: #fff;
}
.max-home .mune-button{
	/* position: absolute; */
	/* left: 0;
	bottom: 1.78rem; */
	padding-left: 29px;
	background: #000;
}
.max-home .mune-button>button{
	position: relative;
	width: 156px;
	height: 42px;
	line-height: 42px;
	border: solid 1px #fff;
	text-align: center;
	color: #fff;
	background-color: transparent;
	display: block;
	font-size: 16px;
	cursor: pointer;
}
.max-home .mune-button>button:first-child{
	margin-bottom: 12px;
}
.max-home .mobile-language>h3{
	display: inline-block;
	line-height: 20px;
	font-size: 16px;
}
.max-home .mobile-language-arrow{
	display: inline-block;
	margin-left: 4px;
	width: 24px;
	height: 24px;
}
.max-home .mobile-language-arrow>img{
	
}
.max-home .mobile-language-choose{
	display: none;
	position: absolute;
	top: 3px;
	left: 2px;
	width: 100%;
	list-style: none;
	background-color: black;
	border: 1px solid #FFF;
}
.max-home .mobile-language-choose>li{
	line-height: 28px;
	font-size: 16px;
	padding-top: 6px;
}
.max-home .home-header{
	position: relative;
	height: 59px;
	width: 100%;
}
.max-home .home-header>div{
	
}
.max-home .mobile .home-nav{
	position: absolute;
	top: 0;
	width: 59px;
	height: 100%;
	left: 0;
}
.max-home .nav-button{
	position: absolute;
	top: 23px;
	left: 19px;
}
.max-home .nav-button>i{
	display: block;
	margin-bottom: 6px;
	width: 17px;
	height: 1px;
	background-color: #FFFFFF;
}
.max-home .mobile-nav .home-header>h1{
	padding-top: 11px;
	margin-left: 52px;
	width: 47px;
	height: 23px;
}
.max-home .mobile-nav .home-header>h1>a>img{
	  /* width: 3.42rem;*/
	/*height: 2.57rem; */
	width: 172px;
	height: 16px;
	margin-top: 5px;
}
.max-home .mobile-header-right{
	position: absolute;
	height: 100%;
	top: 0;
	right: 20px;
}
.max-home .mobile-nav .home-cart{
	display: inline-block;
	width: 59px;
	height: 100%;
	line-height: 59px;
}
.max-home .mobile-nav .home-cart>img{
	display: block;
	margin: 21px auto;
	width: 22px;
	height: 18px;
}
.max-home .mobile-nav .product-num{
	position: absolute;
	top: 13px;
	left: 32px;
	width: 16px;
	height: 16px;
	line-height: 16px;
	text-align: center;
}
.max-home .mobile-nav .product-num>i{
	display: inline-block;
	width: 16px;
	height: 16px;
	background-color: #FFFFFF;
	border-radius: 50%;
}
.max-home .mobile-nav .product-num>span{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	font-size: 12px;
	color: #000000;
}
.max-home .mobile-header-right-button{
	vertical-align: top;
	height: 100%;
	display: inline-block;
}
.max-home .mobile-header-right-button>button, .max-home .mobile-header-right-button>a{
	display: block;
	margin-top: 15px;
	width: 79px;
	height: 29px;
	line-height: 29px;
	border: none;
	color: #000;
	font-size: 12px;
	text-align: center;
	background-color: #F8E71C;
}

.pc-language-choose{
  display: none;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
}
.pc-language-choose>div{
  width: 100%;
  line-height: 35px;
  color: #999;
  font-size: 14px;
  text-align:center;
}
.cartName{
  position: absolute;
  top: 35%;
  left: 50%;
  width: 14px;
  height: 14px;
  line-height:14px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
}
.cartName>span{
  font-size: 10px;
  color:#000;
  font-weight: 400
}