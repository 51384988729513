.slide-2d {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.slide-carousel .item-list {
  position: relative;
  height: 100%;
  /*width: 5780px;*/
 width: 100%;
  
  overflow: hidden;
  transition: all 0.5s;
}
.slide-carousel .item-list>li{
	display: none;
	float: left;
  position: relative;
  height: 100%;
  /*width: 578px;*/
 	width: 100%;
  overflow: hidden;

}
.slide-carousel>.item-list>li>.item-content {
  width: 100%;
  height: 100%;
}
.slide-carousel>.item-list>li>.item-content a {
  display: block;
  width: 100%;
  height: 100%;
}
.slide-carousel>.item-list>li>.item-content>a>.cover-img {
  width: 100%;
  height: 100%;
}

.controls a {
	display: block;
  cursor: pointer;
  text-decoration: none;
  width: 14px;
  height: 30px;
  position: absolute;
  z-index: 2;
}

.controls .item-prev {
  top: 50%;
  left: -34px;
  margin-top: -15px;
}
.controls .item-next {
  top: 50%;
  right: -34px;
  margin-top: -15px;
}


