.trial-kit-box h3 {
    height: 40px;
}

.max-home .kit-price-info2.trial-kit-box p span.very-large {
    font-size: 45px;
    line-height: 45px;
    margin-top: 25px;
}

.max-home .shopping-content-item1 {
    width: 48%;
}

.max-home .shopping-content-item2 {
    width: 44%;
}

.max-home .shopping.shopping2 .scroll-down {
    margin-left: -80px;
}

@media screen and (max-height: 920px) {
    .max-home .shopping.shopping2 .scroll-down {
        display: none;
    }
}

@media screen and (max-height: 880px) {
    .max-home .shopping.shopping2 .scroll-down {
        display: block;
    }
}

@media screen and (max-height: 810px) {
    .max-home .shopping.shopping2 .scroll-down {
        display: none;
    }
}

@media screen and (max-height: 700px) {
    .max-home .kit-price-info2.trial-kit-box p span.very-large {
        font-size: 30px;
        line-height: 33px;
        margin-top: 16px;
    }
    .max-home .kit-price-info2.trial-kit-box {
        width: 385px;
    }
}

@media screen and (min-width: 2000px) {
    .max-home .shopping-content-item2 {
        width: 43%;
    }
}

.max-home .mobile-shopping-kit .bold.very-strong {
    display: block;
    margin-top: 10px;
    font-size: 24px;
    line-height: 28px;
}