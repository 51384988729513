.max-home .mobile-model6{
	position: relative;
	width: 100%;
	/* height: 100%; */
}
.max-home .mobil-emodel6-video{
	padding-top: 28px;
}
.max-home .mobil-model6-video-inner{
	width: 100%;
	height: 230px;
}
.max-home .mobile-model6-details{
	text-align: center;
	margin-top: 0rem;
}
.max-home .mobile-model6-details h4{
	color: #fff;
	margin-top: 10px;
}
.max-home .mobile-model6-details .smallasterisk {
	font-size: 18px;
	vertical-align: top;
}
.max-home .mobile-model6-details>h2{
	margin: 0 auto;
	width: 270px;
	line-height: 21px;
	font-size: 17px;
	color: #F8E71C;
}
.max-home .mobile-model6-details>h1{
	margin: 17px auto 0;
	width: 320px;
	line-height: 31px;
	font-size: 25px;
	color: #fff;
}
.max-home .mobile-model6-details>p{
	margin: 17px auto 16px;
	line-height: 18px;
	font-size: 15px;
	color: #F8E71C;
	text-decoration: line-through;
}
.max-home .mobile-model6-details>button, .max-home .mobile-model6-details>a{
	display: block;
	margin: 0 auto;
	width: 213px;
	height: 44px;
	line-height: 44px;
	text-align: center;
	border: none;
	color: #000;
	background-color: #F8E71C;
	font-weight: bold;
}
@media screen and (max-height: 650px) {
	.max-home .model6 .model6-details h2 {
		font-size: 20px;
		line-height: 24px;
		height: 24px;
	}
	.max-home .model6 .model6-details h1 {
		font-size: 28px;
		line-height: 34px;
		height: 34px;
	}
	.max-home .model6 .model6-details p {
		font-size: 14px;
		line-height: 16px;
		height: 16px;
	}
	.max-home .model6 .model6-details button {
		padding: 12px 12px;
		font-size: 12px;
	}
}
@media screen and (min-width: 2000px){
	.max-home .model6>video{
		position: absolute;
		top: 10%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 0;
	}
}

@media screen and (max-width: 2000px){

	.max-home .model6>video{
		position: absolute;
		top: 10%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 0;
	}
	
}

@media screen and (min-height: 880px) {
	.max-home .model6>video{
		top: 5%;
	}
}

@media screen and (min-height: 880px) and (min-width: 2000px) {
	.max-home .model6>video{
		top: 10%;
	}
}