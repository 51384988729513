.max-home .mobile-model2-info{
	text-align: center;
	width: 100%;
	padding: 56px 0 16px;
}
.max-home .mobile-model2-info>div{
	position: relative;
	display: inline-block;
	
}
.max-home .mobile-model2-icon{
  position: relative;
	display: inline-block;
	margin-right: 27px;
	display: inline-block;
	width: 48px;
	height: 48px;
	border: 1px solid #fff;
	margin-bottom: 5px;
}
.max-home .mobile-model2-icon>i{
	display: inline-block;
	width: 48px;
	height: 48px;
	position: absolute;
	top: 6px;
	left:-9px;
	border: 1px solid #fff;
}
.max-home .mobile-model2-icon>span{
	position: absolute;
	top: 18px;
	left: 7px;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
}
.max-home .mobile-model2-pic{
	position: relative;
	width: 100%;
	height: 19.57rem;
}
.max-home .mobile-model2-pic>img{
	width: 23rem;
    height: 11.8rem;
    position: absolute;
    top: 3.8rem;
    left: 2.1rem;
    /*transform: rotateX(35deg);*/
}
.max-home .mobile-model2-des{
	text-align: left;
	display: inline-block;
	line-height: 18px;
}
.max-home .mobile-model2-des>p{
	color: #9B9B9B;
	font-size: 16px;
	line-height: 18px;
}
.max-home .mobile-model2-des>h3{
	font-size: 16px;
	color: #fff;
}
.max-home .mobile-model2-model2{
	/* margin-top: 5.57rem; */
}
.max-home .mobile-model2-model2>img{
	display: block;
	margin: 0 auto;
	width: 52px;
	height: 36px;
}
.max-home .size{
	margin: 14px 0 20px;
	width: 100%;
	text-align: center;
	list-style: none;
}
.max-home .size>li{
	margin-right: 16px;
	display: inline-block;
	font-size: 12px;
	font-weight: 300;
	color: #fff;
	line-height: 18px;
}
.max-home .size>li:last-child{
	margin: 0;
}
.max-home .size>li>span{
	margin-left: 7px;
	font-size:16px;
}
/*PC 端canvas*/
.max-home #pc-model2-canvas,.max-home #pc-model3-canvas,.max-home #pc-model4-canvas,.max-home #pc-model5-canvas{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.max-home #mobile-model2-canvas{
	display: block;
	position: absolute;
	top: 0.92rem;
	left: 0;
	z-index: 11;
}

.pcModelTwo-size{
	display: none;
	position: absolute;
	color: #fff;
}
.pcModelTwo-size>span:first-child{
	font-size: 12px;
}
.pcModelTwo-size>span:last-child{
	margin-left: 3px;
	font-size: 14px;
}