.faq{
  margin: 0 auto;
}
.faq .title{
  margin: 20px 0 36px;
  line-height: 32px;
  font-size: 28px;
  color: #FFF;
  text-align: center;
}
.faq .content{
  width: 100%; 
}
.faq .content>div>p{
  margin-bottom: 20px;
  font-size: 14px;
  color:#999;
  line-height: 16px;
}
.faq .content>div>h2{
  margin-bottom: 40px;
  margin-top: 60px;
  font-size: 24px;
   color: #fff;
}
.faq .content>div>h3{
  font-size: 16px;
  color: #fff;
  margin-bottom: 5px;
}
.faq .content>div>p>span{
  /*display: block;*/
  font-size: 14px;
}
 @media screen and (min-width: 1250px){
   .faq .title{
      line-height: 40px;
      font-size: 36px;
   }
    .faq .content>div>p{
       font-size: 18px;
        line-height: 20px;
        margin-bottom: 25px;
    }
    .faq .content>div>h2{
      font-size: 30px;
      color: #fff;
    }
    .faq .content>div>h3{
      font-size: 20px;
      margin-bottom: 7px;
    }
 }

 @media screen and (min-width: 2000px){
    .faq .title{
        margin: 30px 0 54px;
        line-height: 63px;
        font-size: 54px;
    }
    .faq .content>div>p{
      font-size: 27px;
       line-height: 30px;
       margin-bottom: 38px;
   }
    .faq .content>div>h2{
      font-size: 45px;
      color: #fff;
      margin-bottom: 60px;
      margin-top: 90px;
    }
    .faq .content>div>h3{
      font-size: 30px;
      margin-bottom: 11px;
    }
 }