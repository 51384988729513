.max-home .mobile-service{
	position: relative;
	width: 100%;
	/* height: 100%; */
}
.max-home .mobile-service1-slide{
	padding-top: 6.35rem;
}
.max-home .mobile-service2-slide{
	padding-top: 18px;
}
.max-home .mobile-service1-slide-inner{
	position: relative;
	width: 100%;
	height: 29.64rem;
}
.max-home .mobile-service2-slide-inner{
	position: relative;
	width: 100%;
	height: 15.28rem;
}
.max-home .service1-slide-list{
	position: absolute;
	top: 0;
	left: 0;
	list-style: none;
	width: 100%;
	height: 100%;
}
.max-home .service2-slide-list{
	left: 50%;
	margin-left: -9.71rem;
	width: 19.42rem;
}
.max-home .service1-slide-list>li{
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	width: 100%;
	height: 100%;
}
.max-home .service1-slide-list>li>img{
	display: block;
	width: 100%;
	height: 100%;
}
.max-home .service1-slide-controls{
	position: absolute;
	width: 100%;
	height: 100%;
}
.max-home .service1-slide-controls>a{
	position: absolute;
	top: 50%;
	margin-top: -1.07rem;
}
.max-home .service1-slide-controls>a:first-child{
	left: 1.14rem;
}
.max-home .service1-slide-controls>a:last-child{
	right: 1.14rem;
}
.max-home .mobile-service-icon{
	margin: 0 auto 0;
	width: 180px;
	height: 105px;
	padding-top: 35px;
}
.max-home .mobile-service-icon>img{
	width: 180px;
	/*height: 100%;*/
}
.max-home .mobile-service2-info{
	margin: 18px auto 21px;
	width: 345px;
	line-height: 19px;
}
.max-home .mobile-service2-info>p{
	line-height: 18px;
	font-size: 16px;
	color: #9B9B9B;
	text-align: center;
	padding: 0 13px;
}
.max-home .mobile-service2-click{
	text-align: center;
	margin: 0 auto;
	width: 22.5rem;
	height: 3.21rem;
	line-height: 3.21rem;
	background-color: #FF8800;
}
.max-home a.mobile-service2-click{
	text-align: center;
	margin: 0 auto;
	width: 310px;
	height: 44px;
	line-height: 44px;
	background-color: #FF8800;
	display: block;
}
.max-home .mobile-service2-click>img{
	display: inline-block;
	width: 18px;
	height: 20px;
	vertical-align: middle;
}
.max-home .mobile-service2-click>p, .max-home .mobile-service2-click>span{
	margin-left: 11px;
	display: inline-block;
	color: #000000;
	font-size: 14px;
}
.max-home .mobile-service2-click>span {
	line-height: 24px;
}